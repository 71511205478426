import React from 'react';
import { Container } from 'theme-ui';
import SEO from '../../components/seo';
import PrimaryLayout from '../../components/layout/primary/primary';
import Amplify, { Auth } from 'aws-amplify';
import AuthStateApp from '../../components/Authenticator/AuthStateApp'
import Details from '../../components/profile/details';
import awsconfig from '../../aws-exports';

Amplify.configure(awsconfig);

const DetailsPage: React.FC<{}> = () => {

const [user, setUser] = React.useState({firstName: '', lastName: '', phone_number: '', email: ''});
React.useEffect(() => {
		async function getUser() {
			try {
			  let userInfo = await Amplify.Auth.currentUserInfo();
			  setUser({firstName: userInfo.attributes['custom:firstName'], lastName: userInfo.attributes['custom:LastName'], phone_number: userInfo.attributes.phone_number, email: userInfo.attributes.email})
	  
			} catch (error) {
				console.log('error getting details: ', error);
			}
		}
	  getUser();	
    }, []);
    
return (
	
	<PrimaryLayout>
		<SEO title="INDUSFOODS.US Profile" />
		<Container py="40px">
		<AuthStateApp>
			< Details userInfo={user}/>
		</AuthStateApp>	
		</Container>
	</PrimaryLayout>
	
);
}

export default DetailsPage;
